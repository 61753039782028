<template>
  <div class="whole">
    <Nav></Nav>
    <div class="usershowlist desktop-present">
      <!-- 特约摄影师 -->
      <div class="show-list" v-if="$route.query.code == '4'">
        <!-- <div class="list-title"></div> -->
        <div class="list-con">
          <div class="list-a">
            <div class="list-left">ABCDEFG</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupABCDEFG" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">HIJKLMN</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupHIJKLMN" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">OPQRST</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupOPQRST" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">UVW</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupUVW" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">XYZ</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupXYS" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 摄影记者 -->
      <div class="show-list" v-if="$route.query.code == '2'">
        <div class="list-sec"></div>
        <div class="list-con">
          <div class="list-a">
            <div class="list-left">ABCDEFG</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupABCDEFG" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">HIJKLMN</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupHIJKLMN" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">OPQRST</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupOPQRST" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">UVW</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupUVW" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">XYZ</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupXYS" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 摄影名家 -->
      <div class="show-list" v-if="$route.query.code == '3'">
        <div class="list-thr"></div>
        <div class="list-con">
          <div class="list-a">
            <div class="list-left">ABCDEFG</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupABCDEFG" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">HIJKLMN</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupHIJKLMN" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">OPQRST</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupOPQRST" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">UVW</div>
            <div class="show-list">
              <!-- <div class="list-right" v-for="k in groupUVW" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div> -->
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">XYZ</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupXYS" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="usershowlist mobile-present">
      <!-- 特约摄影师 -->
      <div class="show-list" v-if="$route.query.code == '1'">
        <div class="list-title"></div>
        <div class="list-con">
          <div class="list-a">
            <div class="list-left">ABCDEFG</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupABCDEFG" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">HIJKLMN</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupHIJKLMN" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">OPQRST</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupOPQRST" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">UVW</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupUVW" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">XYZ</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupXYS" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 摄影记者 -->
      <div class="show-list" v-if="$route.query.code == '2'">
        <div class="list-sec"></div>
        <div class="list-con">
          <div class="list-a">
            <div class="list-left">ABCDEFG</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupABCDEFG" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">HIJKLMN</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupHIJKLMN" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">OPQRST</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupOPQRST" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">UVW</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupUVW" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">XYZ</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupXYS" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 摄影名家 -->
      <div class="show-list" v-if="$route.query.code == '3'">
        <div class="list-thr"></div>
        <div class="list-con">
          <div class="list-a">
            <div class="list-left">ABCDEFG</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupABCDEFG" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">HIJKLMN</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupHIJKLMN" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">OPQRST</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupOPQRST" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">UVW</div>
            <div class="show-list">
              <!-- <div class="list-right" v-for="k in groupUVW" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div> -->
            </div>
          </div>
          <div class="list-a">
            <div class="list-left">XYZ</div>
            <div class="show-list">
              <div class="list-right" v-for="k in groupXYS" :key="k.id">
                <div
                  :style="{ backgroundImage: `url(` + k.imgURL + `)` }"
                  class="img"
                  @click="onDetail(k.id)"
                ></div>
                <div class="show-name">{{ k.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: { Nav, Bottom },
  data: function() {
    return {
      reporterList: [], //记者列表
      groupABCDEFG: [],
      groupHIJKLMN: [],
      groupOPQRST: [],
      groupUVW: [],
      groupXYS: []
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      RD.userShow()
        .byTypeCode(this.$route.query.code)
        .then(data => {
          var newList = data.sort(function(a, b) {
            return a.nameEn.localeCompare(b.nameEn);
          });
          thiz.groupABCDEFG = [];
          thiz.groupHIJKLMN = [];
          thiz.groupOPQRST = [];
          thiz.groupUVW = [];
          thiz.groupXYS = [];
          newList.forEach(k => {
            var first = k.nameEn.slice(0, 1);
            switch (first) {
              case "a":
              case "b":
              case "c":
              case "d":
              case "e":
              case "f":
              case "g":
                thiz.groupABCDEFG.push(k);
                break;
              case "h":
              case "i":
              case "j":
              case "k":
              case "l":
              case "m":
              case "n":
                thiz.groupHIJKLMN.push(k);
                break;
              case "o":
              case "p":
              case "q":
              case "r":
              case "s":
              case "t":
                thiz.groupOPQRST.push(k);
                break;
              case "u":
              case "v":
              case "w":
                thiz.groupUVW.push(k);
                break;
              case "x":
              case "y":
              case "z":
                thiz.groupXYS.push(k);
                break;
            }
            thiz.groupABCDEFG.forEach(i => {
              i.imgURL = RD.userShow().id(i.id).headPic_URL();
            });
            thiz.groupHIJKLMN.forEach(i => {
              i.imgURL = RD.userShow().id(i.id).headPic_URL();
            });
            thiz.groupOPQRST.forEach(i => {
              i.imgURL = RD.userShow().id(i.id).headPic_URL();
            });
            thiz.groupUVW.forEach(i => {
              i.imgURL = RD.userShow().id(i.id).headPic_URL();
            });
            thiz.groupXYS.forEach(i => {
              i.imgURL = RD.userShow().id(i.id).headPic_URL();
            });
          });
          thiz.reporterList = data;
          console.log('jkk', thiz.reporterList);
        });
    },
    // 具体摄影者
    onDetail(id) {
      this.$router.push({
        path: "/userShowDetail.html",
        query: {id:id, code: this.$route.query.code }
      });
    }
  }
};
</script>

<style scoped lang="less">
.usershowlist.desktop-present {
  height: auto;
  background-color: #f1f1f1;
  .show-list {
    width: 120rem;
    margin: 0 auto;
    .list-title {
      text-align: left;
      height: 35rem;
      background: no-repeat 50% url(../assets/teyuesheying.png);
    }
    .list-sec {
      text-align: left;
      height: 35rem;
      background: no-repeat 50% url(../assets/reporter.png);
    }
    .list-thr {
      text-align: left;
      height: 35rem;
      background: no-repeat 50% url(../assets/sheyingmingjia.png);
    }
    .list-con {
      .list-a {
        height: auto;
        border: 0.1rem solid #b5b5b5;
        border-bottom: 0.5rem solid #b5b5b5;
        display: flex;
        flex-shrink: 0;
        margin-bottom: 3rem;
        padding: 1rem 0rem;
        .list-left {
          width: 25rem;
          height: 18rem;
          line-height: 18rem;
          font-weight: bold;
          font-size: 2.5rem;
        }
        .show-list {
          display: flex;
          flex-wrap: wrap;
          .list-right {
            margin-right: 3rem;
            margin-top: 1rem;
            .img {
              width: 13.5rem;
              height: 13.5rem;
              border-radius: 50%;
              background-size: cover;
              overflow: hidden;
            }
            .show-name {
              height: 3rem;
              line-height: 3rem;
              margin-top: 1rem;
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}
.usershowlist.mobile-present {
  height: auto;
  background-color: #f1f1f1;
  .show-list {
    width: 750px;
    margin: 0 auto;
    .list-title {
      text-align: left;
      height: 350px;
      background: no-repeat 50% url(../assets/teyuesheying.png);
    }
    .list-sec {
      text-align: left;
      height: 350px;
      background: no-repeat 50% url(../assets/reporter.png);
    }
    .list-thr {
      text-align: left;
      height: 350px;
      background: no-repeat 50% url(../assets/sheyingmingjia.png);
    }
    .list-con {
      .list-a {
        height: auto;
        border: 0.1rem solid #b5b5b5;
        border-bottom: 0.5rem solid #b5b5b5;
        display: flex;
        flex-shrink: 0;
        margin-bottom: 3rem;
        padding: 1rem 0rem;
        .list-left {
          width: 206px;
          height: 180px;
          line-height: 180px;
          font-weight: bold;
          font-size: 22px;
        }
        .show-list {
          display: flex;
          flex-wrap: wrap;
          .list-right {
            margin-right: 30px;
            margin-top: 10px;
            .img {
              width: 135px;
              height: 135px;
              border-radius: 50%;
              background-size: cover;
              overflow: hidden;
            }
            .show-name {
              height: 30px;
              line-height: 30px;
              margin-top: 10px;
              font-size: 1.3rem;
            }
          }
        }
      }
    }
  }
}
</style>
